import { Router } from '@angular/router';
import { TestService } from '../../../core/test-service/test.service';
import { TestGeneratorOptions } from '../../../core/interfaces/test';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { ProfileService } from '../../../core/profile-service/profile.service';
import { QuestionService } from 'src/app/core/question-service/question.service';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { SimulationService } from 'src/app/core/simulation-service/simulation.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import moment from 'moment';
import { TestAutoGenerator } from 'src/app/core/interfaces/test_auto_generator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { FormsModule } from '@angular/forms';
import { GhostOverlayModule } from '../../ghost-overlay/ghost-overlay.module';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { ConfigService } from '../../../core/config/config.service';
import { upperCaseFromUnderscore } from '../../../core/util/string.util';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';
import { showCat2 } from '../../../core/config/config.util';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

export interface CustomSimulationSingleInfo {
  category?: string;
  cat1?: string;
  cat2?: string;
  cat3?: string;
  question_number?: number;
  difficulty?: number;
}

@Component({
  selector: 'app-custom-simulation-dialog',
  templateUrl: './custom-simulation-dialog.component.html',
  styleUrls: ['./custom-simulation-dialog.component.scss'],
  standalone: true,
  animations: [
    trigger('removeItem', [
      state('*', style({ overflow: 'hidden' })),
      transition('* => void', [
        animate(
          '250ms ease-in-out',
          style({
            height: 0,
          })
        ),
      ]),
    ]),
  ],
  imports: [
    BaseDialogComponent,
    FormsModule,
    TranslocoModule,
    GhostOverlayModule,
    MatIcon,
    NgClass,
    MatDialogActions,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatOptgroup,
  ],
})
export class CustomSimulationDialogComponent implements OnInit {
  custom_simulations_option: Array<CustomSimulationSingleInfo> = new Array<CustomSimulationSingleInfo>();

  categories = new Array<string>();

  cat1 = new Array<string>();
  cat2 = new Array<string>();

  public state: ComponentState = COMPONENT_STATE.LOADING;

  readonly MAX_QUESTIONS_COUNT = 80;
  readonly MAX_SECTIONS_COUNT = 5;
  duration_each_question = 0;

  duration = 0;
  custom_simulation_name = '';

  QUESTION_POINTS?: { correct: number; wrong: number; blank: number };
  questions_count: { [key: string]: number } = {};
  min = Math.min;
  max = Math.max;

  public readonly showCat2Filter = showCat2();

  constructor(
    private elRef: ElementRef,
    private profileService: ProfileService,
    private uiService: UiService,
    private translate: TranslocoService,
    private dialogRef: MatDialogRef<CustomSimulationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<CustomSimulationSingleInfo>,
    private simulationService: SimulationService,
    private testService: TestService,
    private router: Router,
    private question: QuestionService,
    configService: ConfigService
  ) {
    if (data) this.custom_simulations_option = data;
    // this.state = ComponentState.GHOST;

    configService.getSimulationConfig('_DURATION_EACH_QUESTION').subscribe(duration => (this.duration_each_question = duration));
    forkJoin([this.profileService.getCategories(), this.question.getQuestionCount()]).subscribe({
      next: ([categories, questions]) => {
        this.categories = categories;
        this.questions_count = questions;
        this.cat1 = Array.from(new Set(categories.map(cat => cat.split('.')[0])));
        if (this.custom_simulations_option.length) {
          this.custom_simulations_option[0].cat1 = this.custom_simulations_option[0].cat1 || this.cat1[0] || '';
        }
        this.state = COMPONENT_STATE.NONE;
      },
      error: err => {
        console.error(err);
        this.dialogRef.close();
        this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
      },
    });

    this.simulationService.getPoints().subscribe(points => {
      this.QUESTION_POINTS = points;
    });
  }

  getCategoryQuestionCount(category: string): number {
    if (!this.categories.length) return -1;
    if (Object.entries(this.questions_count).length == 0) return 10;
    if (category.endsWith('.')) category = category.slice(0, -1);
    return Object.entries(this.questions_count)
      .filter(([key, _]) => key.startsWith(category))
      .map(([_, value]) => +value)
      .reduce((a, b) => a + b, 0);
  }

  ngOnInit(): void {
    if (this.custom_simulations_option.length == 0) this.addCategory(true);

    this.custom_simulation_name = this.translate.translate('simulation.extra.custom.title') + ' ' + moment().format('DD/MM/YYYY');
  }

  displayCategory(cat: string): string {
    return cat
      .split(' ')
      .map(w => upperCaseFromUnderscore(w))
      .join(' ');
  }

  getCats2(cat1: string): Array<string> {
    return Array.from(new Set(this.categories.filter(cat => cat.split('.')[0] == cat1).map(cat => cat.split('.')[1])));
  }

  onCat1Change(event: Event, option: CustomSimulationSingleInfo): void {
    option.cat2 = '';
    option.cat3 = '';
  }

  questionCount(): number {
    return this.custom_simulations_option.reduce((acc, val) => acc + (val.question_number || 0), 0);
  }

  addCategory(disableScroll?: boolean): void {
    this.custom_simulations_option.push({
      category: '',
      cat1: this.cat1[0] || '',
      cat2: '',
      cat3: '',
      question_number: 10,
      difficulty: 0,
    });

    if (disableScroll) {
      return;
    }
    const dialogContent = (this.elRef.nativeElement as HTMLElement).querySelector('.mat-mdc-dialog-content');
    if (!dialogContent) {
      return;
    }

    setTimeout(() => {
      // dialogContent.scrollTop = dialogContent.scrollHeight;
      dialogContent.scrollTo({ top: dialogContent.scrollHeight, behavior: 'smooth' });
      // dialogContent.scrollIntoView({ block: 'end', inline: 'end', behavior: 'smooth' });
    });
  }

  invalidCategory(): boolean {
    return this.custom_simulations_option.some(option => option.cat1 == '');
  }

  create(): void {
    if (this.custom_simulations_option.length == 0) {
      this.uiService.errorToast({ title: this.translate.translate('simulation.extra.custom.errors.no_section') });
      return;
    }

    if (this.custom_simulation_name.trim() == '') {
      this.uiService.errorToast({ title: this.translate.translate('simulation.extra.custom.errors.no_name') });
      return;
    }

    if (this.questionCount() > this.MAX_QUESTIONS_COUNT) {
      this.uiService.errorToast({ title: this.translate.translate('simulation.extra.custom.errors.too_many_questions', { count: this.MAX_QUESTIONS_COUNT }) });
      return;
    }

    if (this.invalidCategory()) {
      this.uiService.errorToast({ title: this.translate.translate('simulation.extra.custom.errors.invalid_category') });
      return;
    }

    if (!this.QUESTION_POINTS) return;

    const questions_generator = new Array<TestAutoGenerator>();

    this.custom_simulations_option.forEach(option => {
      // let category: string = "";
      // if(option.cat3) category = option.cat3;
      // else if(option.cat1) category = option.cat1;
      // if(option.cat2) category += "." + option.cat2;

      const category = [option.cat1, option.cat2, option.cat3].filter(c => c).join('.');

      questions_generator.push({
        category_path: category || '',
        difficulty: option.difficulty || 0,
        count: option.question_number || 10,
        name: this.displayCategory(category.split('.')[0]),
      });
    });

    const option: TestGeneratorOptions = {
      name: this.custom_simulation_name.trim(),
      duration: this.duration,
      type: 'custom',
      questions_generator: questions_generator,
      score_correct: this.QUESTION_POINTS.correct,
      score_wrong: this.QUESTION_POINTS.wrong,
      score_blank: this.QUESTION_POINTS.blank,
    };

    this.uiService.showSpinner = true;
    this.testService
      .generate(option)
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: test => {
          this.dialogRef.close(test);
          this.router.navigate(['/test', test.instance_id]);
        },
        error: () => this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') }),
      });
  }

  getTime(): string {
    const minutes = Math.floor(this.questionCount() * this.duration_each_question);
    const minutes_formatted = minutes >= 9 ? '' + minutes : '0' + minutes;
    this.duration = minutes;
    return minutes_formatted != '00' ? minutes_formatted + ' min ' : '';
  }

  removeSection(index: number): void {
    if (this.custom_simulations_option.length <= 1) {
      this.uiService.errorToast({ title: this.translate.translate('simulation.extra.custom.errors.lastOption') });
      return;
    }

    this.custom_simulations_option = this.custom_simulations_option.filter((_, i) => i != index);
  }
}
